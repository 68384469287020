import {RadStatisticDto} from 'src/app/interfaces/backend/dto/rad-statistic-dto.interface';
import {RadiologyTaskDto} from 'src/app/interfaces/backend/dto/radiology-task-dto.interface';
import {AmChartData, AmChartValue} from 'src/app/interfaces/core/am-chart/am-chart-data.interface';
import {TypedObject} from 'src/app/interfaces/core/typed-object.interface';
import {getDateLogic} from 'src/app/utilities/format.util';
import {arrayToObject, sortByDates} from 'src/app/utilities/object.util';

export const mapRadStatisticDtoToAmChartData = (data: RadStatisticDto) => {
    const {categoryField, values} = data;
    const result: AmChartData = {
        categoryField,
        values
    };

    return result;
};

export const mapRadStatisticDtoListToAmChartDataList = (data: RadStatisticDto[]) => {
    const result: AmChartData[] = data.map(entry => mapRadStatisticDtoToAmChartData(entry));
    return result;
};

export const mapRadStatisticDtoToAmChartDataEachUnit = (data: RadStatisticDto) => {
    const result: AmChartData = {
        categoryField: data.categoryField,
        values: data.values,
        valuesFieldsTranslationPrefix: 'enums.RadStatus.'
    };

    return result;
};

export const mapRadStatisticDtoListToAmChartDataListEachUnit = (data: RadStatisticDto[]) => {
    const result: AmChartData[] = data.map(entry => mapRadStatisticDtoToAmChartDataEachUnit(entry));
    return result;
};

export const parseAmChartValue = (data: AmChartValue) => {
    const result: TypedObject<any> = {
        [data.field]: data.value
    };

    return result;
};

export const parseAmChartData = (data: AmChartData) => {
    const result: TypedObject<any> = {
        categoryField: data.categoryField,
        ...arrayToObject(data.values.filter(entry => entry.value).map(entry => parseAmChartValue(entry)))
    };

    return result;
};

export const parseAmChartDataList = (data: AmChartData[]) => {
    const result: TypedObject<any>[] = data.map(entry => parseAmChartData(entry));
    return result;
};

export const mapRadiologyTaskDtoToDailyTasksByStatusesAmChartDataList = (data: RadiologyTaskDto[]): AmChartData[] => {
    const result: AmChartData[] = [];
    const dateTaskMap: Record<string, Record<string, number>> = {};
    const sortedData = data.sort(sortByDates('dateCreate'));
    sortedData.forEach(task => {
        if (!task.dateCreate || !task.status) return;

        const date = getDateLogic(task.dateCreate);
        const taskStatus = task.status;

        if (!dateTaskMap[date]) dateTaskMap[date] = {};

        if (!dateTaskMap[date][taskStatus]) dateTaskMap[date][taskStatus] = 0;

        dateTaskMap[date][taskStatus]++;
    });

    Object.keys(dateTaskMap).forEach(date => {
        const statusCounts = dateTaskMap[date];
        const values: AmChartValue[] = Object.keys(statusCounts).map(status => ({
            field: 'enums.RadStatus.' + status,
            value: statusCounts[status]
        }));

        result.push({
            categoryField: date,
            values
        });
    });

    return result;
};

export const mapRadiologyTaskDtoToDailyTasksByPriorityAmChartDataList = (data: RadiologyTaskDto[]): AmChartData[] => {
    const result: AmChartData[] = [];
    const dateTaskMap: Record<string, Record<string, number>> = {};

    data.forEach(task => {
        if (!task.dateCreate || !task.priority) return;

        const date = getDateLogic(task.dateCreate);
        const priority = task.priority;

        if (!dateTaskMap[date]) dateTaskMap[date] = {};

        if (!dateTaskMap[date][priority]) dateTaskMap[date][priority] = 0;

        dateTaskMap[date][priority]++;
    });

    Object.keys(dateTaskMap).forEach(date => {
        const priorityCounts = dateTaskMap[date];
        const values: AmChartValue[] = Object.keys(priorityCounts).map(priority => ({
            field: priority,
            value: priorityCounts[priority]
        }));

        result.push({
            categoryField: date,
            values
        });
    });

    return result;
};

export const mapRadiologyTaskDtoToDailyTasksByAmChartDataList = (data: RadiologyTaskDto[]): AmChartData[] => {
    const result: AmChartData[] = [];
    const dateTaskMap: Record<string, Record<string, number>> = {};

    data.forEach(task => {
        if (!task.assignedDoctor || !task.dateCreate || !task.procedure) return;

        const doctor = task.assignedDoctor;
        const procedure = task.procedure;

        if (!dateTaskMap[doctor]) dateTaskMap[doctor] = {};

        if (!dateTaskMap[doctor][procedure]) dateTaskMap[doctor][procedure] = 0;

        dateTaskMap[doctor][procedure]++;
    });

    Object.keys(dateTaskMap).forEach(doctor => {
        const proceduresCount = dateTaskMap[doctor];
        const values: AmChartValue[] = Object.keys(proceduresCount).map(procedure => ({
            field: procedure,
            value: proceduresCount[procedure]
        }));

        result.push({
            categoryField: doctor,
            values
        });
    });

    return result;
};