import {Injectable} from '@angular/core';
import {map, tap} from 'rxjs';
import {RadStatus} from 'src/app/enums/backend/rad-status.enum';
import {FileExtension} from 'src/app/enums/core/file-extension.enum';
import {Page} from 'src/app/interfaces/backend/core/page.interface';
import {Pageable} from 'src/app/interfaces/backend/core/pageable.interface';
import {RadiologyTaskDto} from 'src/app/interfaces/backend/dto/radiology-task-dto.interface';
import {RadiologyTaskReportDto} from 'src/app/interfaces/backend/dto/radiology-task-report-dto.interface';
import {RadiologyFormsReportFilter} from 'src/app/interfaces/backend/other/radiology-forms-report-filter.intrerface';
import {RadiologyTaskStatusCount} from 'src/app/interfaces/backend/other/radiology-task-status-count.interface';
import {
    RadiologyFormsHistoryFilter
} from 'src/app/interfaces/backend/pageable-filters/radiology-forms-history-filter.interface';
import {AmChartData} from 'src/app/interfaces/core/am-chart/am-chart-data.interface';
import {
    mapRadiologyTaskDtoToDailyTasksByAmChartDataList,
    mapRadiologyTaskDtoToDailyTasksByPriorityAmChartDataList,
    mapRadiologyTaskDtoToDailyTasksByStatusesAmChartDataList
} from 'src/app/mappers/charts.mapper';
import {mapRadiologyTaskStatusCountMapToRadiologyTaskStatusCountList} from 'src/app/mappers/reports.mapper';
import {RadiologyControllerService} from 'src/app/services/backend/radiology-controller.service';
import {downloadFileFromBlob} from 'src/app/utilities/file.util';

@Injectable({providedIn: 'root'})
export class ReportsFacade {
    public dataPage: Page<RadiologyTaskReportDto> | null = null;
    public dailyRadiologyTasksStatusesByStatusChartData: AmChartData[] = [];
    public dailyRadiologyTasksStatusesByStatus: RadiologyTaskDto[] = [];
    public dailyRadiologistRadiologyTasksByPriorityChartData: AmChartData[] = [];
    public dailyRadiologistRadiologyTasksByPriority: RadiologyTaskDto[] = [];
    public dailyRadiologistRadiologyTasksByProcedureChartData: AmChartData[] = [];
    public dailyRadiologistRadiologyTasksByProcedure: RadiologyTaskDto[] = [];
    public radiologyTasksCountByStatusForUnit: RadiologyTaskStatusCount[] = [];
    public radiologyTasksCountByStatusForRadiologist: RadiologyTaskStatusCount[] = [];

    constructor(
        private radiologyControllerService: RadiologyControllerService
    ) {
    }

    public reset() {
        this.dataPage = null;
        this.dailyRadiologyTasksStatusesByStatusChartData = [];
        this.dailyRadiologyTasksStatusesByStatus = [];
        this.dailyRadiologistRadiologyTasksByPriorityChartData = [];
        this.dailyRadiologistRadiologyTasksByPriority = [];
        this.dailyRadiologistRadiologyTasksByProcedureChartData = [];
        this.dailyRadiologistRadiologyTasksByProcedure = [];
        this.radiologyTasksCountByStatusForUnit = [];
        this.radiologyTasksCountByStatusForRadiologist = [];
    }

    public loadRadiologyTasksReport(pageable: Pageable, filter: RadiologyFormsReportFilter) {
        this.loadRadiologyTasksReportLogic(pageable, filter);
    }

    public loadRadiologyTasksReportLogic(pageable: Pageable, filter: RadiologyFormsReportFilter) {
        return this.getRadiologyTasksReport(pageable, filter).pipe(
            tap(data => this.dataPage = data)
        );
    }

    public getRadiologyTasksReport(pageable: Pageable, filter: RadiologyFormsReportFilter) {
        return this.radiologyControllerService.getRadiologyTasksReport(pageable, filter);
    }

    public getDailyRadiologyTasksByStatuses(pageable: Pageable = {}, filter: RadiologyFormsReportFilter) {
        this.radiologyControllerService.getRadiologyTasks(pageable, filter).subscribe((value) => {
            this.dailyRadiologyTasksStatusesByStatus = value.content ?? [];
            this.dailyRadiologyTasksStatusesByStatusChartData = mapRadiologyTaskDtoToDailyTasksByStatusesAmChartDataList(value.content ?? []);
        });
    }

    public getDailyRadiologyTasksByPriority(pageable: Pageable = {}, filter: RadiologyFormsHistoryFilter) {
        this.radiologyControllerService.getRadiologyTasks(pageable, filter).subscribe((value) => {
            this.dailyRadiologistRadiologyTasksByPriority = value.content ?? [];
            this.dailyRadiologistRadiologyTasksByPriorityChartData = mapRadiologyTaskDtoToDailyTasksByPriorityAmChartDataList(value.content ?? []);
        });
    }

    public getDailyRadiologistRadiologyTasksByProcedure(pageable: Pageable = {}, filter: RadiologyFormsHistoryFilter) {
        this.radiologyControllerService.getRadiologyTasks(pageable, filter).subscribe((value) => {
            this.dailyRadiologistRadiologyTasksByProcedure = value.content ?? [];
            this.dailyRadiologistRadiologyTasksByProcedureChartData = mapRadiologyTaskDtoToDailyTasksByAmChartDataList(value.content ?? []);
        });
    }

    public getRadiologyTasksCountByStatusForUnit(unitId: number, from?: string, to?: string) {
        return this.radiologyControllerService.getRadiologyTasksCountByStatusForUnit(unitId, from, to).pipe(
            map(data => this.sortRadiologyTaskStatusCount(mapRadiologyTaskStatusCountMapToRadiologyTaskStatusCountList(data))),
            tap(data => this.radiologyTasksCountByStatusForUnit = data)
        );
    }

    public getRadiologyTasksCountByStatusForRadiologist(userId: number, from?: string, to?: string) {
        return this.radiologyControllerService.getRadiologyTasksCountByStatusForRadiologist(userId, from, to).pipe(
            map(data => this.sortRadiologyTaskStatusCount(mapRadiologyTaskStatusCountMapToRadiologyTaskStatusCountList(data))),
            tap(data => this.radiologyTasksCountByStatusForRadiologist = data)
        );
    }

    public getRadiologyTasksCountByStatusForUnitAsCSV(unitId: number, from?: string, to?: string) {
        return this.radiologyControllerService.getRadiologyTasksCountByStatusForUnitAsCSV(unitId, from, to)
                   .subscribe(data => downloadFileFromBlob(data, FileExtension.CSV));
    }

    public getRadiologyTasksCountByStatusForRadiologistAsCSV(userId: number, from?: string, to?: string) {
        return this.radiologyControllerService.getRadiologyTasksCountByStatusForRadiologistAsCSV(userId, from, to)
                   .subscribe(data => downloadFileFromBlob(data, FileExtension.CSV));
    }

    private sortRadiologyTaskStatusCount(data: RadiologyTaskStatusCount[]) {
        const template = Object.values(RadStatus);
        const result = [...data].sort((a: RadiologyTaskStatusCount, b: RadiologyTaskStatusCount) => {
            if (!a.status) return 1;
            if (!b.status) return -1;

            const indexA = template.indexOf(a.status);
            const indexB = template.indexOf(b.status);
            return (indexA > -1 ? indexA : Infinity) - (indexB > -1 ? indexB : Infinity);
        });

        return result;
    }
}