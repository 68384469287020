import {RadStatus} from 'src/app/enums/backend/rad-status.enum';
import {RadiologyTaskStatusCountMap} from 'src/app/interfaces/backend/other/radiology-task-status-count-map.interface';
import {RadiologyTaskStatusCount} from 'src/app/interfaces/backend/other/radiology-task-status-count.interface';

export const mapRadiologyTaskStatusCountMapToRadiologyTaskStatusCount = (status: RadStatus, count: number) => {
    const result: RadiologyTaskStatusCount = {status, count};
    return result;
};

export const mapRadiologyTaskStatusCountMapToRadiologyTaskStatusCountList = (data: RadiologyTaskStatusCountMap) => {
    const result: RadiologyTaskStatusCount[] = Object.keys(data)
                                                     .map((entry) => mapRadiologyTaskStatusCountMapToRadiologyTaskStatusCount(entry as RadStatus, data[entry]));
    return result;
};